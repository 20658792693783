export const NumberUtil = {
    addCommas(nStr){

      if (typeof nStr!=='string'){
        if(typeof nStr === 'number'){
          nStr = String(nStr.toFixed(2))
        } else {
          nStr = String(nStr)
        }
      }

      nStr += '';
      let x = nStr.split('.');
      let x1 = x[0];
      let x2 = x.length > 1 ? '.' + x[1] : '';

      if( x.length > 1 && x[1].length===1) {
        x2 = x.length > 1 ? '.' + x[1]+'0' : '';
      }

      if( x.length === 1 ) {
        x2 = x.length === 1 ? '.' +'00' : '';
      }

      /*let x2 = ''
      if ( x.length > 1) {
        x2 = '.' + x[1]
        if (x[1] === '00') {
          x2 = ''
        }
      } else {
        x2 = ''
      }*/

      let rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        // eslint-disable-next-line
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      let result = x1 + x2
      if (result==='.00') {
        return '' ;
      }
      return result;
    },
  addCommasZeroInt(nStr){

    if (nStr === '') {
      return ''
    }

    if (typeof nStr!=='string'){
      if(typeof nStr === 'number'){
        nStr = String(nStr.toFixed(2))
      } else {
        nStr = String(nStr)
      }
    }

    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    // let x2 = x.length > 1 ? '.' + x[1] : '';
    let x2 = ''
    if ( x.length > 1) {
      x2 = '.' + x[1]
      if (x[1] === '00') {
        x2 = ''
      }
    } else {
      x2 = ''
    }

    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      // eslint-disable-next-line
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  },
  replaceCarMiles(mile_with_comma) {
      if(mile_with_comma.includes('0,000')){
        return mile_with_comma.replace('0,000', 'x,xxx')
      } else if (mile_with_comma.includes('000')){
        return mile_with_comma.replace('000', 'xxx')
      }
      return mile_with_comma

  },
  removeComma(strNumber) {
      let str = strNumber

      if (str === '') {
        return  ''
      }

      if (typeof strNumber !== 'string' ) {
        str = String(str)
      }
     let strNoComma = str.replaceAll(',', '')
     return strNoComma
  },
    mobileFormat(mobileNumber){

      if (mobileNumber.length > 0) {
        if (mobileNumber.includes('-')) {
          return mobileNumber
        }
      }

      const splitAt = index => x => [x.slice(0, index), x.slice(index)]
      let splitMobile = splitAt(3)(mobileNumber)
      return splitMobile[0] + "-" + splitMobile[1]
    },

  decimalAdjust(type, value, exp) {

    // Decimal round
    // const round10 = (value, exp) => decimalAdjust("round", value, exp);
    // Decimal floor
    // const floor10 = (value, exp) => decimalAdjust("floor", value, exp);
    // Decimal ceil
    // const ceil10 = (value, exp) => decimalAdjust("ceil", value, exp);

    type = String(type);
    if (!["round", "floor", "ceil"].includes(type)) {
      throw new TypeError(
          "The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'.",
      );
    }
    exp = Number(exp);
    value = Number(value);
    if (exp % 1 !== 0 || Number.isNaN(value)) {
      return NaN;
    } else if (exp === 0) {
      return Math[type](value);
    }
    const [magnitude, exponent = 0] = value.toString().split("e");
    const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`);
    // Shift back
    const [newMagnitude, newExponent = 0] = adjustedValue.toString().split("e");
    return Number(`${newMagnitude}e${+newExponent + exp}`);
  },
  isNumber(num) {
    let _ = require('lodash');
    return _.isNumber(num);
  },
  padZeroLeft(number, digits) {
     return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
  }
}
